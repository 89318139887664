import { combineEpics, Epic } from 'redux-observable'
import { forkJoin } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { isOfType } from 'typesafe-actions'
import * as AppAction from '../actions/app'
import * as ActionConstants from '../data/actionConstants'
import { DEFAULT_SIGN_UP_ERROR_MESSAGE } from '../data/constants'
import { getPartnerCode, getPreFillValue } from '../selectors/index'
import i18n from '../services/i18n'
import RequestService, { CheckSubscriptionResponseParams } from '../services/requests'
import { ResponseParams } from '../services/requests'
import RouteService from '../services/routes'
import UrlParamsService from '../services/urlParams'
import { RootAction } from '../store/types'

const handleAppGetInit: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => isOfType(ActionConstants.APP_GET_INIT, action)),
    map(action => {
      const params = UrlParamsService.getParams()
      if (!params.partnerCode) {
        return AppAction.displayUnknownError()
      }
      return AppAction.appInitState(params)
    })
  )

const authEndpoint = RouteService.getRoutes().auth
const subscriptionCheck = RouteService.getRoutes().subscriptionCheck

const handleSignUpSubmitRequest: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => isOfType(ActionConstants.APP_SIGN_UP_REQUEST, action)),
    mergeMap(action => {
      const preFillValues = getPreFillValue(store.value)
      const partnerCode = getPartnerCode(store.value)
      const params = {
        email: preFillValues.email || action.payload.email,
        first_name: preFillValues.firstName || action.payload.firstName,
        last_name: preFillValues.lastName || action.payload.lastName,
        password: action.payload.password,
        partner_code: partnerCode,
      }
      const queue = RequestService.makeRequest<ResponseParams>(params, authEndpoint)
      return forkJoin(queue).pipe(
        map((response: ResponseParams[]) => {
          const res = response.pop()
          if (res?.status !== 'success') {
            return AppAction.signUpSubmitFailure(res?.errors?.full_messages?.join('\n'))
          } else {
            return AppAction.signUpSubmitSuccess()
          }
        })
      )
    })
  )

const handleSubscriptionCheckRequest: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => isOfType(ActionConstants.APP_GET_INIT, action)),
    mergeMap(action => {
      const partnerCode = getPartnerCode(store.value)
      const preFillValues = getPreFillValue(store.value)

      if (!partnerCode || !preFillValues.email) {
        return [AppAction.appShowSignUp()]
      }

      const params = {
        email: preFillValues.email,
        first_name: preFillValues.firstName,
        last_name: preFillValues.lastName,
        partner_code: partnerCode,
      }
      const queue = RequestService.makeRequest<CheckSubscriptionResponseParams>(params, subscriptionCheck)
      return forkJoin(queue).pipe(
        map((response: CheckSubscriptionResponseParams[]) => {
          const res = response.pop()
          if (!res?.success) {
            return AppAction.displayUnknownError()
          }
          if (res.registered) {
            return AppAction.signUpSubmitSuccess()
          }
          return AppAction.appShowSignUp()
        })
      )
    })
  )

const handleLoadStarts: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      isOfType(ActionConstants.APP_SIGN_UP_REQUEST, action)
    ),
    mergeMap(action => [
      AppAction.resetAlertMessage(),
      AppAction.setInLoad(),
    ])
  )

const handleRequestFails: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => isOfType(ActionConstants.APP_SIGN_UP_FAILURE, action)),
    mergeMap(action => [
      AppAction.resetInLoad(),
      AppAction.setAlertMessage(action.payload.message || i18n.t(DEFAULT_SIGN_UP_ERROR_MESSAGE))
    ])
  )

export default combineEpics(
  handleAppGetInit,
  handleLoadStarts,
  handleRequestFails,
  handleSignUpSubmitRequest,
  handleSubscriptionCheckRequest
)
