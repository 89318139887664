import DE from '../assets/locale/de.json'
import EN from '../assets/locale/en.json'
import { DEFAULT_LANGUAGE } from '../data/constants'

type Translates = { [index: string]: string }

type Resource = { [index: string]: { translations: Translates } }

class I18n {
  lngParam: string = 'lng'
  language: string = ''
  lng: Translates = {}
  resources: Resource = { en: { translations: EN }, de: { translations: DE } }
  search = new URLSearchParams(window.location.search)
  init() {
    this.language = this.search.get(this.lngParam) || DEFAULT_LANGUAGE
    this.lng = this.resources[this.language].translations
    return this
  }
  t(key: string) {
    if (this.lng && this.lng[key]) {
      return this.lng[key]
    }
    return key
  }
  changeLanguage(language: string) {
    if(this.language !== language) {
      this.search.set(this.lngParam, language)
      window.location.replace(`${window.location.pathname}?${this.search}`)
    }
  }
}

const i18n = new I18n()

export default i18n.init()
