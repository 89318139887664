import * as React from 'react'
import { SUPPORT_EMAIL } from '../data/constants'
import i18n from '../services/i18n'

const UnknownError = () => (
  <div className="form-container centerContent textAlignCenter mt-5">
    <div className="form-group">
      <div className="form-group">{i18n.t('UNKNOWN_ERROR')}</div>
    </div>

    <a
      className="btn btn-primary col-12 col-sm-6 col-lg-5 mt-5"
      href={`mailto:${SUPPORT_EMAIL}`}
    >
      {i18n.t('CONTACT_SUPPORT')}
    </a>
  </div>
)

export default UnknownError
