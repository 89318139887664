export const DEFAULT_SIGN_UP_ERROR_MESSAGE = 'SIGN_UP_FAILED'

export const DEFAULT_AES256_KEY = '5TUfdUrW21vkNhLuex5KJUQxMvwoIhhO'

export const DEFAULT_LANGUAGE = 'en'

export const SUPPORT_EMAIL = 'support@mindshine.freshdesk.com'

export const ViewTypes = {
  load: 'load',
  signUp: 'sign-up',
  congratulations: 'congratulations',
  unknownError: 'unknown-error',
}
