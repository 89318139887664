import * as React from 'react'
import appStore from '../assets/images/app-store-badge.png'
import googlePlay from '../assets/images/google-play-badge.png'
import i18n from '../services/i18n'

const Downloads = () => (
  <div className="form-group row">
    <div className="col">
      <a className="" href={i18n.t('APP_STORE_URL')}>
        <img className="downloadBadgeAppStore" src={appStore} alt="app-store-badge"/>
      </a>
    </div>
    <div className="col downloadBadge">
      <a className="" href={i18n.t('PLAY_STORE_URL')}>
        <img className="downloadBadgePlayStore" src={googlePlay} alt="google-play-badge"/>
      </a>
    </div>
  </div>
)

export default Downloads
