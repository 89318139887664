import * as React from 'react'

interface ButtonProps {
  disabled?: boolean
  label: string
}

const Button = (props: ButtonProps) => (
  <div className="form-group textAlignCenter">
    <button
      type="submit"
      disabled={props.disabled}
      className="btn btn-primary col-12 col-md-6 col-lg-5 col-xl-3"
      data-dismiss="alert"
    >
      {props.label}
    </button>
  </div>
)

export default Button
