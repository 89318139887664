import { hosts, routes } from '../data/links'
import getEnvironment from './environment'

class Routes {
  routes: {[index: string]: string} = {}
  init() {
    const env = getEnvironment()
    const host = hosts[env]
    Object.keys(routes).map((key: string) => this.routes[key] = `${host}${routes[key]}`)
    return this
  }
  get(key: string) {
    return this.routes[key]
  }
  getRoutes() {
    return this.routes
  }
}

const RouteService = new Routes()

export default RouteService.init()
