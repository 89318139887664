import * as React from 'react'
import logo from '../assets/images/Logo.png'

const Logo = () => (
  <div className="mindshineLogoContainer">
    <img src={logo} className="mindshineLogo" alt="logo"/>
  </div>
)

export default Logo
