import { RootState } from '../store/types.d'

export const getPreFillValue = (state: RootState) => ({
  email: state.app.email,
  firstName: state.app.firstName,
  lastName: state.app.lastName,
})

export const getErrorMessage = (state: RootState) => ({
  show: state.app.showError,
  message: state.app.errorMessage,
})

export const getActiveView = (state: RootState) => state.app.activeView

export const getPartnerCode = (state: RootState) => state.app.partnerCode

export const isInLoad = (state: RootState) => state.app.inLoad
