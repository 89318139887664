import * as React from 'react'
import { SubmitSignUpParams } from '../actions/app'
import i18n from '../services/i18n'
import Button from './Form/Button'
import Input from './Form/Input'
import PrivacyPolicy from './Form/PrivacyPolicy'

interface SignUpFormProps {
  values: {
    firstName: string
    lastName: string
    email: string
  }
  disabled: boolean
  handleSubmit: (submitProps: SubmitSignUpParams) => void
}

const SignUpForm = (props: SignUpFormProps) => {
  const [firstName, setFirstName] = React.useState(props.values?.firstName)
  const [lastName, setLastName] = React.useState(props.values?.lastName)
  const [email, setEmail] = React.useState(props.values?.email)
  const [password, setPassword] = React.useState('')

  React.useEffect(() => {
    if (props.values) {
      if (props.values.firstName) {
        setFirstName(props.values.firstName)
      }
      if (props.values.lastName) {
        setLastName(props.values?.lastName)
      }
      if (props.values.email) {
        setEmail(props.values?.email)
      }
    }
  }, [props.values])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    props.handleSubmit({ firstName, lastName, email, password })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        id="firstName"
        placeholder={i18n.t('FIRST_NAME_PLACEHOLDER')}
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
        disabled={!!props.values?.firstName}
        required={!props.values?.firstName}
      />
      <Input
        id="lastName"
        placeholder={i18n.t('LAST_NAME_PLACEHOLDER')}
        value={lastName}
        onChange={e => setLastName(e.target.value)}
        disabled={!!props.values?.lastName}
        required={!props.values?.lastName}
      />
      <Input
        id="email"
        placeholder={i18n.t('EMAIL_PLACEHOLDER')}
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        disabled={!!props.values?.email}
        required={!props.values?.email}
      />
      <Input
        id="password"
        placeholder={i18n.t('PASSWORD_PLACEHOLDER')}
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        required
      />
      <PrivacyPolicy />
      <Button disabled={props.disabled} label={i18n.t('SIGN_UP')} />
    </form>
  )
}

export default SignUpForm
