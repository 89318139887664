import { action } from 'typesafe-actions'
import * as ActionConstants from '../data/actionConstants'

export interface SignUpRequestParams {
  endpoint: string
  email: string
  first_name: string
  last_name: string
  password: string
}

export interface SubmitSignUpParams {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const appGetInit = () => action(ActionConstants.APP_GET_INIT)
export const appInitState = (params: { email: string, firstName: string, lastName: string, partnerCode: string }) =>
  action(ActionConstants.APP_INIT_STATE, { ...params })
export const appShowSignUp = () => action(ActionConstants.APP_SHOW_SIGN_UP)

export const signUpSubmitRequest = (submitProps: SubmitSignUpParams) =>
  action(ActionConstants.APP_SIGN_UP_REQUEST, submitProps)
export const signUpSubmitSuccess = () =>
  action(ActionConstants.APP_SIGN_UP_SUCCESS)
export const signUpSubmitFailure = (message?: string ) =>
  action(ActionConstants.APP_SIGN_UP_FAILURE, { message })

export const setInLoad = () =>
  action(ActionConstants.APP_SET_IN_LOAD, { inLoad: true })
export const resetInLoad = () =>
  action(ActionConstants.APP_SET_IN_LOAD, { inLoad: false })

export const setAlertMessage = (errorMessage: string) =>
    action(ActionConstants.APP_SET_ALERT_MESSAGE, { showError: true, errorMessage })
export const resetAlertMessage = () =>
    action(ActionConstants.APP_SET_ALERT_MESSAGE, { showError: false, errorMessage: '' })

export const displayUnknownError = () => action(ActionConstants.APP_DISPLAY_UNKNOWN_ERROR)
