import * as React from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  wrappedClassName?: string,
  forwardRef?: React.RefObject<HTMLInputElement>
}

const defaultInputProps: InputProps = {
  wrappedClassName: 'form-group',
  className: 'form-control',
  type: 'text'
}

const Input = (props: InputProps) => {
  const { forwardRef, wrappedClassName, ...inputProps } = {
    ...defaultInputProps,
    ...props,
    name: `${props.id}Input`
  }
  return (
    <div className={wrappedClassName}>
      <input {...inputProps} ref={forwardRef}/>
    </div>
  )
}

export default Input
