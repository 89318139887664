import * as React from 'react'
import i18n from '../../services/i18n'

const PrivacyPolicy = () => (
  <div className="form-group textAlignCenter agbForm">
    <span className="agb">
      {i18n.t('SIGN_UP_AGREEMENT_PART_ONE')}
      <a href={i18n.t('TERMS_AND_CONDITIONS_URL')} target="_blank" rel="noopener noreferrer">
        {i18n.t('TERMS_AND_CONDITIONS')}
      </a>
      {' '}{i18n.t('AND')}{' '}
      <a href={i18n.t('PRIVACY_POLICY_URL')} target="_blank" rel="noopener noreferrer">
        {i18n.t('PRIVACY_POLICY')}
      </a>.
    </span>
  </div>
)

export default PrivacyPolicy
