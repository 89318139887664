import Aes256Service from '../services/encryption'

const fullNameReg = /^(?<firstName>.*)\s(?<lastName>.*)$/

class UrlParams {
  search = new URLSearchParams(window.location.search)
  getParams() {
    const names = this.getNames()
    return {
      email: this.getDecryptedParam(['email']),
      firstName: names.firstName,
      lastName: names.lastName,
      partnerCode: this.search.get('partner_code') || '',
    }
  }
  getNames() {
    const name = this.getDecryptedParam(['name', 'fullname', 'full_name'])
    if(name) {
      const names = fullNameReg.exec(name)
      return {
        firstName: names?.groups?.firstName || '',
        lastName: names?.groups?.lastName || '',
      }
    }
    return {
      firstName: this.getDecryptedParam(['first_name', 'firstname']),
      lastName: this.getDecryptedParam(['last_name', 'lastname']),
    }
  }
  getDecryptedParam(needle: string[]) {
    let param = null
    needle.forEach(key => {
      if (this.search.has(key)) {
        param = this.search.get(key)
      }
    })
    return param ? Aes256Service.decrypt(param) : ''
  }
}

const UrlParamsService = new UrlParams()
export default UrlParamsService
