export const hosts = {
  'prod': 'https://api.mindshine.app',
  'stage': 'https://api-dev.mindshine.app',
  'dev': 'http://127.0.0.1:3000',
}

export const links: { [index: string]: string } = {
  downloadPage: 'https://checkout.mindshine.app/download',
  lookupApi: 'https://ipinfo.io?token=4dd4f6d3424c68',
}

export const routes: { [index: string]: string } = {
  auth: '/auth',
  subscriptionCheck: '/check_partners_subscription',
}
