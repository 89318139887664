import Axios, { AxiosStatic } from 'axios'
import i18n from './i18n'

export interface RequestParams {
  [index: string]: string | number
}

export interface ResponseParams {
  // data: any
  errors?: { full_messages: string[] }
  status: string | number
}

export interface CheckSubscriptionResponseParams {
  success: boolean
  message?: string
  registered?: boolean
  subscribed?: boolean
}

class Request {
  constructor(public transport: AxiosStatic = Axios) { }

  makeRequest<T>(params: RequestParams, endpoint: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.transport
        .post(
          endpoint,
          JSON.stringify(params),
          {
            maxRedirects: 0,
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': i18n.language,
            }
          }
        )
        .then(res => {
          resolve(res?.data)
        })
        .catch(res => {
          resolve(res?.response?.data)
        })
    })
  }
}

const RequestService = new Request()
export default RequestService
