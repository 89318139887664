import * as React from 'react'

interface LoaderProps {
  display: boolean
}

const Loader = (props: LoaderProps) => (
  <div className={['spinner-container loader', props.display ? '' :'hide'].join(' ').trim()}>
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  </div>
)

export default Loader
