import * as React from 'react'

export interface AlertProps {
  message: string
  show: boolean
}

const Alert = (props: AlertProps) => (
  <div className={['preLine textAlignCenter alert alert-danger', props.show ? '' : 'd-none'].join(' ').trim()}>
    {props.message}
  </div>
)

export default Alert
