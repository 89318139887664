import { applyMiddleware, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'

import rootEpic from './root-epic'
import rootReducer from './root-reducer'

export const epicMiddleware = createEpicMiddleware()

function configureStore() {
  const createdStore = createStore(
    rootReducer,
    applyMiddleware(epicMiddleware)
  )
  epicMiddleware.run(rootEpic)
  return createdStore
}

const store = configureStore()
export default store
