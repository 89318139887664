import * as React from 'react'
import congratulations from '../assets/images/congratulations.png'
import i18n from '../services/i18n'
import Downloads from './Downloads'

const Congratulations = () => (
  <div className="form-container centerContent textAlignCenter">
    <div className="form-group ">
      <img src={congratulations} className="congratulationsLogo" alt="congratulations"/>
    </div>

    <div className="form-group">
      <div className="form-group">{i18n.t('DOWNLOAD_CONGRATZ')}</div>
      <div className="form-group">{i18n.t('DOWNLOAD_ACTION')}</div>
    </div>

    <Downloads />
  </div>
)

export default Congratulations
