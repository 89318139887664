import * as React from 'react'
import i18n from '../services/i18n'

const Languages = () => (
  <div className="col-6 col-md-2 ml-auto language-switcher d-flex align-items-center mr-5">
    {Object.keys(i18n.resources).map(lng =>
      <button
        key={lng}
        type="button"
        className={['link-button language', lng === i18n.language ? 'selected' : ''].join(' ').trim()}
        onClick={e => i18n.changeLanguage(lng)}
      >
        {lng.toUpperCase()}
      </button>
    )}
  </div>
)

export default Languages
