import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './assets/styles/index.scss' // Internal css
import App from './Components/App'
import store from './store'

const rootElement = document.getElementById('mindshine-sign-up-root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
)
