import * as React from 'react'
import { connect } from 'react-redux'
import { signUpSubmitRequest, SubmitSignUpParams } from '../actions/app'
import { getErrorMessage, getPreFillValue, isInLoad } from '../selectors/index'
import { RootAction, RootState } from '../store/types'
import Alert, { AlertProps }  from './Alert'
import Loader from './Loader'
import SignUpForm from './SignUpForm'

interface SignUpProps {
  values: {
    firstName: string
    lastName: string
    email: string
  }
  signUp: (submitProps: SubmitSignUpParams) => void
  errorMessage: AlertProps
  inLoad: boolean
}

const SignUp = (props: SignUpProps) => {
  return (
    <div className="col-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
      <SignUpForm
        values={props.values}
        disabled={props.inLoad}
        handleSubmit={props.signUp}
      />
      <Loader display={props.inLoad} />
      <Alert {...props.errorMessage} />
    </div>
  )
}

const mapStateToProps = (
  state: RootState
) => ({
  values: getPreFillValue(state),
  errorMessage: getErrorMessage(state),
  inLoad: isInLoad(state)
})

const mapDispatchToProps = (
  dispatch: (action: RootAction) => void
) => ({
  signUp: (submitProps: SubmitSignUpParams) => dispatch(signUpSubmitRequest(submitProps)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)
