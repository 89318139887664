import aes256 from 'aes-everywhere'
import { DEFAULT_AES256_KEY } from '../data/constants'

class Aes256 {
  encrypt(value: string) {
    return aes256.encrypt(value, DEFAULT_AES256_KEY)
  }
  decrypt(value: string) {
    return aes256.decrypt(value, DEFAULT_AES256_KEY)
  }
}

const Aes256Service = new Aes256()
export default Aes256Service
