import * as React from 'react'
import { connect } from 'react-redux'
import { appGetInit } from '../actions/app'
import { ViewTypes } from '../data/constants'
import { getActiveView } from '../selectors/index'
import { RootAction, RootState } from '../store/types'
import Congratulations from './Congratulations'
import Languages from './Languages'
import Loader from './Loader'
import Logo from './Logo'
import SignUp from './SignUp'
import UnknownError from './UnknownError'

interface AppProps {
  activeView: string
  applicationGetInit: () => void
}

const Load = () => (<Loader display={true} />)

const Views: { [index: string]: React.ReactType } = {
  [ViewTypes.load]: Load,
  [ViewTypes.signUp]: SignUp,
  [ViewTypes.congratulations]: Congratulations,
  [ViewTypes.unknownError]: UnknownError,
}

const App = (props: AppProps) => {
  React.useEffect(() => {
    props.applicationGetInit()
  }, [])

  const View = Views[props.activeView]
  return (
    <div>
      <Logo />
      <Languages />
      <View />
    </div>
  )
}

const mapStateToProps = (
  state: RootState
) => ({
  activeView: getActiveView(state)
})

const mapDispatchToProps = (
  dispatch: (action: RootAction) => void
) => ({
  applicationGetInit: () => {
    dispatch(appGetInit())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
