import { combineReducers } from 'redux'
import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/app'
import * as ActionConstants from '../data/actionConstants'
import { ViewTypes } from '../data/constants'

export type AppAction = ActionType<typeof actions>

export type AppState = {
  email: string
  firstName: string
  lastName: string
  errorMessage: string
  showError: boolean
  inLoad: boolean
  partnerCode: string
  activeView: string
}

const defaultAppState: AppState = {
  email: '',
  firstName: '',
  lastName: '',
  errorMessage: '',
  showError: false,
  inLoad: false,
  partnerCode: '',
  activeView: ViewTypes.load,
}

const rootReducer = combineReducers({
  app: (
    state: AppState = defaultAppState,
    action: AppAction
  ) => {
    switch (action.type) {
      case ActionConstants.APP_INIT_STATE:
        return { ...state, ...action.payload }
      case ActionConstants.APP_SET_IN_LOAD:
        return { ...state, ...action.payload }
      case ActionConstants.APP_SET_ALERT_MESSAGE:
        return { ...state, ...action.payload }
      case ActionConstants.APP_SHOW_SIGN_UP:
        return { ...state, activeView: ViewTypes.signUp }
      case ActionConstants.APP_SIGN_UP_SUCCESS:
        return { ...state, activeView: ViewTypes.congratulations }
      case ActionConstants.APP_DISPLAY_UNKNOWN_ERROR:
        return { ...state, activeView: ViewTypes.unknownError }
      default:
        return state
    }
  },
})

export default rootReducer
